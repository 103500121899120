import React from "react"
import { Link } from "gatsby"

import Layout from "../shared/components/Layout/Layout"
import Seo from "../Seo/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Link to="/">Go back to the home page?</Link>
  </Layout>
)

export default NotFoundPage
